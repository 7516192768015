import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { TopMenu } from '../menus/TopMenu';

export const MAIN_MENU_HEIGHT = 48;

const Header = styled('header')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal - 1,
  }),
);

const Main = styled('div')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: `${MAIN_MENU_HEIGHT}px`,
    bottom: 0,
    left: 0,
    right: 0,
  }),
);

export const AllDBelPagesLayout = () => {
  return (
    <>
      <Header>
        <TopMenu />
      </Header>
      <Main>
        <Outlet />
      </Main>
    </>
  );
};
